import Box from '@mui/material/Box';

import logoGma from '../app/landing/imgs/logo-GMA.png';
import logoUMonash from '../app/landing/imgs/logo-umonash.png';

export const Collaborators = () => {
  return (
    <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}>
      <img src={logoGma} alt="Growing Minds Australia Logo" height={55} />
      <img src={logoUMonash} alt="Monash University Logo" height={55} />
    </Box>
  );
};
