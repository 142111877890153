import { useEffect, useMemo, useState } from 'react';
import { Box, ButtonBase, ButtonBaseProps, Typography, useTheme } from '@mui/material';
import CheckedIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import { Ring } from 'components/Ring';
import { Survey } from '@vega/common';

export type SurveyCardProps = {
  id: string;
  title: string;
  src: string;
  tint: string;
  extraStyle?: Survey['extraStyle'];
  progress: number | null;
  transitionDelay: number;
} & Pick<ButtonBaseProps, 'onClick'>;

export function SurveyCard(props: SurveyCardProps) {
  const { id, progress, transitionDelay, tint, src, title, extraStyle, onClick } = props;
  const enabled = progress !== null;
  const inner = useMemo(() => {
    switch (progress) {
      case 1:
        return <CheckedIcon />;
      case null:
        return <LockIcon />;
      default:
        return `${Math.round(progress * 100)}%`;
    }
  }, [progress]);
  const theme = useTheme();
  const contrastColour = theme.palette.getContrastText(tint);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '10rem',
      }}
      role="figure"
      aria-label={id}>
      <ButtonBase
        sx={{
          top: 0,
          width: '100%',
          height: '100%',
          borderRadius: 2,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          gap: 4,
          color: contrastColour,
          boxShadow: 1,
          backgroundColor: enabled ? tint : '#666',
          opacity: enabled ? 1 : 0.5,
        }}
        disabled={!enabled}
        aria-label={id}
        aria-disabled={!enabled}
        onClick={onClick}
        focusRipple>
        <Cover src={src} extraStyle={extraStyle} transitionDelay={transitionDelay} enabled={enabled} />
        <Box
          component="section"
          sx={{
            display: 'flex',
            p: 2,
            justifyContent: 'space-around',
            flexDirection: 'column',
            alignItems: 'flex-end',
            color: contrastColour,
          }}>
          <Typography
            align="right"
            sx={{
              fontFamily: 'caveat',
              lineHeight: 1.2,
              fontSize: '1.8rem',
            }}>
            {title}
          </Typography>
          <Box>
            <Ring color={contrastColour} percent={progress || 0} size="small" name={id}>
              {inner}
            </Ring>
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
}

const Cover = (props: {
  src: string;
  enabled: boolean;
  transitionDelay?: number;
  extraStyle?: Survey['extraStyle'];
}) => {
  const { src, enabled, transitionDelay = 0, extraStyle = {} } = props;
  const [slideIn, setSlideIn] = useState(false);
  useEffect(() => {
    setTimeout(() => setSlideIn(true), transitionDelay);
  }, []);

  return (
    <Box
      sx={(theme) => ({
        '& > img': {
          display: 'block',
          height: '100%',
          filter: enabled ? 'none' : 'grayscale(100%)',
          transform: 'translateY(100%)',
          opacity: 0,
          transition: theme.transitions.create(['transform', 'opacity'], { duration: 1000 }),
          ...extraStyle,
          ...(slideIn && {
            transform: 'translateY(0)',
            opacity: 1,
          }),
        },
      })}>
      <img src={src} alt="cover" />
    </Box>
  );
};
