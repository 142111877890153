// Given an element or null, if the element exists, scroll it to
// the top of the screen
export const scrollToElement = (element: Element | null) => {
  const container = document.querySelector('main');
  // We scroll within the main content container.
  // It is the second child of main, since the toolbar is the
  // first element
  const parent = document.querySelector('main > div:nth-child(2)');

  if (element && container && parent) {
    container.scrollTo({
      top: element.getBoundingClientRect().top - parent.getBoundingClientRect().top,
      behavior: 'smooth',
    });
  }
};
